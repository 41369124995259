import React, { useEffect } from 'react';
import { ThemeProvider, Container, Box, Grid } from '@material-ui/core';
import theme from '../theme';
import Header from '../components/Header';
import MetabaseDashboard from '../components/MetabaseDashboard';
import MapCard from '../components/MapCard';
import CopyRight from '../components/CopyRight';
import {getEntities, getWaterbodies, getEntityCoordinates, getGalleryImages} from '../services/entity';
import {_listMapToOptions, _listToOptions, _valToOption} from '../utils/dropdown'
import {navigate} from 'gatsby'
import SlidShowCard from '../components/SlideShowCard';
import Item from '../components/ItemComponent'


export default function Home() {
  const [dashboard1, setDashboard1] = React.useState(35);
  const [dashboard2, setDashboard2] = React.useState(36);
  const [dashboard3, setDashboard3] = React.useState(37);

  const [entityType, setEntityType] = React.useState('country');
  const [states, setStates] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [blocks, setBlocks] = React.useState([]);
  const [villages, setVillages] = React.useState([]);
  const [waterBodies, setWaterBodies] = React.useState([]);

  const [country, setCountry] = React.useState('INDIA');
  const [state, setState] = React.useState(null);
  const [district, setDistrict] = React.useState(null);
  const [block, setBlock] = React.useState(null);
  const [village, setVillage] = React.useState(null);
  const [waterBody, setWaterBody] = React.useState(null);

  // const [entityVerticalValues, setEntityVerticalValues] = React.useState([]);
  const [galleryImageList, setGalleryImageList] = React.useState([]);
  const [entityCoordinates, setEntityCoordinates] = React.useState(null);
  const [waterBodyMapDetails, setWaterBodyMapDetails] = React.useState(null);
  const [contourToggle, setContourToggle] = React.useState(true);
  const [orthoToggle, setOrthoToggle] = React.useState(true);
  const [shapesToggle, setShapesToggle] = React.useState(true);

  useEffect(() => {
    console.log(
      "This only happens ONCE.  But it happens AFTER the initial render."
    );
    setCountryValue()
  }, []);


  const nullifyChildDropdownsWithType = (type) => {
    if(type === "country"){
      setState(null)
      setDistrict(null)
      setBlock(null)
      setVillage(null)
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
    else if(type === "state"){
      setDistrict(null)
      setBlock(null)
      setVillage(null)
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
    else if(type === "district"){
      setBlock(null)
      setVillage(null)
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
    else if(type === "block"){
      setVillage(null)
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
    else if(type === "village"){
      setWaterBody(null)
      setWaterBodyMapDetails(null)
    }
  }

  const setCountryValue = () => {
    getGalleryImages({}).then(resp => setGalleryImageList(resp))
    setEntityType('country')
    // getEntityVerticalValues({'type': 'state', 'name': value}).then(resp => setEntityVerticalValues(resp))
    getEntityCoordinates({'type': 'country', 'name': country}).then(resp => setEntityCoordinates(resp))
    getEntities({'type': 'state'}).then(resp => {resp.sort(); setStates(resp)})
    getWaterbodies({}).then(resp => setWaterBodies(resp))
    nullifyChildDropdownsWithType('country')
    setDashboard1(35)
    setDashboard2(36)
    setDashboard3(37)
  }

  const setStateValue = (value) => {
    getGalleryImages({state: value}).then(resp => setGalleryImageList(resp))
    setEntityType('state')
    setState(value);
    // getEntityVerticalValues({'type': 'state', 'name': value}).then(resp => setEntityVerticalValues(resp))
    getEntityCoordinates({'type': 'state', 'name': value}).then(resp => setEntityCoordinates(resp))
    getEntities({'type': 'district', 'parent': value}).then(resp => {resp.sort(); setDistricts(resp)})
    getWaterbodies({'state': value}).then(resp => setWaterBodies(resp))
    nullifyChildDropdownsWithType('state')
    setDashboard1(39)
    setDashboard2(40)
    setDashboard3(41)
  }

  const setDistrictValue = (value) => {
    setEntityType('district')
    setDistrict(value);
    getEntities({'type': 'block', 'parent': value}).then(resp => {resp.sort(); setBlocks(resp)})
    // getEntityVerticalValues({'type': 'district', 'name': value}).then(resp => setEntityVerticalValues(resp))
    getEntityCoordinates({'type': 'district', 'name': value}).then(resp => setEntityCoordinates(resp))
    getWaterbodies({'state': state, 'district': value}).then(resp => setWaterBodies(resp))
    nullifyChildDropdownsWithType('district')
    setDashboard1(42)
    setDashboard2(43)
    setDashboard3(44)
  }

  const setBlockValue = (value) => {
    setEntityType('block')
    setBlock(value);
    getEntities({'type': 'village', 'parent': value}).then(resp => {resp.sort(); setVillages(resp)})
    // getEntityVerticalValues({'type': 'block', 'name': value}).then(resp => setEntityVerticalValues(resp))
    getEntityCoordinates({'type': 'block', 'name': value}).then(resp => setEntityCoordinates(resp))
    getWaterbodies({'state': state, 'district': district, 'block': value}).then(resp => setWaterBodies(resp))
    nullifyChildDropdownsWithType('block')
    setDashboard1(45)
    setDashboard2(46)
    setDashboard3(47)
  }

  const setVillageValue = (value) => {
    setEntityType('village')
    setVillage(value);
    // getEntityVerticalValues({'type': 'village', 'name': value}).then(resp => setEntityVerticalValues(resp))
    getEntityCoordinates({'type': 'village', 'name': value}).then(resp => setEntityCoordinates(resp))
    getWaterbodies({'state': state, 'district': district, 'block': block, 'village': value}).then(resp => {resp.sort(); setWaterBodies(resp)})
    nullifyChildDropdownsWithType('village')
    setDashboard1(48)
    setDashboard2(49)
    setDashboard3(50)
  }

  const setWaterBodyValue = (value) => {
    setEntityType('water_body')
    setWaterBody(value);
    navigate("/water-body/"+ value.water_body_id)
    // setPage('water_body')
    // setWaterBodyId(value.water_body_id)
  }

  const entityToDashboard1 = {
    'country': 35,
    'state': 39,
    'district': 42,
    'state': 45,
    'village': 48
  }

  const entityToDashboard2 = {
    'country': 36,
    'state': 40,
    'district': 43,
    'state': 46,
    'village': 49
  }

  const entityToDashboard3 = {
    'country': 37,
    'state': 41,
    'district': 44,
    'state': 47,
    'village': 50
  }

  // const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  const shapeColorList = [{'name': 'Healthy', 'color': 'blue'},
                          {'name': 'Eutrophication', 'color': 'black'},
                          {'name': 'Dried-Up', 'color': 'red'}, 
                        ]


  return (
    <>
      <ThemeProvider theme={theme}>

        <Header isSelectBar={true} states={states} districts={districts} blocks={blocks} villages={villages} waterBodies={waterBodies}
        state={state} district={district} block={block} village={village} waterBody={waterBody}
        setCountryValue={setCountryValue} height={"160px"}
        setStateValue={setStateValue} setDistrictValue={setDistrictValue} setBlockValue={setBlockValue} setVillageValue={setVillageValue} setWaterBodyValue={setWaterBodyValue}
        />

        <Box
            sx={{
            backgroundColor: '#d3d5e5',
            minHeight: '100%',
            pt: 3,
            pb: 3
            }}
            style={{background: '#d3d5e5'}}
        >
            <Container maxWidth={false}>
              <Grid container spacing={2}>

                  <Grid item xs={12}>
                      <Item>
                        <MetabaseDashboard dashboard={dashboard1} 
                          height={entityType === "village" ? "360px" : "600px"}
                          entityType={entityType} state={state} district={district} block={block} village={village}
                        />
                      </Item>
                  </Grid>

                  <Grid item xs={6}>
                      <Item>
                      <div className="something" style={{height: '100%'}}>
                          <div style={{display: "flex", justifyContent: "right"}}>
                          {shapeColorList.map(s => <div>
                                  <div style={{ width: "12px",
                                    display: "inline-block",
                                    height: "10px",
                                    background: s.color,
                                    margin: "3px 8px",
                                    borderRadius: "2px"
                                  }}>
                                  </div> {s.name}
                                </div>
                          )}
                          </div>
                            <MapCard
                            isMisMarkerShown={true}
                            waterBodies={waterBodies}
                            waterBody={waterBody}
                            waterBodyMapDetails={waterBodyMapDetails}
                            entity={village || block || district || state}
                            contourToggle={contourToggle}
                            orthoToggle={orthoToggle}
                            shapesToggle={shapesToggle}
                            entityCoordinates={entityCoordinates}
                            height={entityType === 'village' ? "780px" : "1170px"}
                          //   setWaterBodyId={setWaterBodyId}
                          //   setPage={setPage}
                            />
                      </div>
                      </Item>
                  </Grid>
                  
                  <Grid item xs={6}>
                      <Item>
                        <MetabaseDashboard dashboard={dashboard2} entityType={entityType} height={entityType === 'village' ? "800px" : "1180px"}  state={state} district={district} block={block} village={village}/>
                      </Item>
                  </Grid>

                  <Grid item xs={12}>
                      <Item>
                        <SlidShowCard images={galleryImageList}/>
                      </Item>
                  </Grid>

                  <Grid item xs={12}>
                      <Item>
                        <MetabaseDashboard dashboard={dashboard3} entityType={entityType} height="410px"  state={state} district={district} block={block} village={village} />
                      </Item>
                  </Grid>

              </Grid>
            </Container>
        </Box>
      
        <CopyRight />
      </ThemeProvider>
    </>
    
  );
}
